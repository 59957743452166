import React from 'react'
import { PageProps } from 'gatsby'
import {
  Head,
  Hero,
  About,
  HowWeDo,
  Team,
  MosaicSection,
  Footer,
  WhatsApp,
} from '~/components'
import { useScroll } from '~/hooks'
import { useApi } from '~/siteApi'
import Banner from '~/assets/img/Banner-sobre.jpg'
import BeABroker from '~/assets/img/Seja-um-corretor.jpg'

interface Context {
  makes: Array<never>
  teams: Array<never>
  boards: Array<never>
  tradings: Array<never>
}

const AboutPage = ({ location, pageContext }: PageProps) => {
  const { makes, teams, boards, tradings }: Context = useApi(
    pageContext,
    'page-about'
  )

  useScroll()

  return (
    <>
      <Head
        location={location}
        title={`Aikon - ${process.env.GATSBY_SITE_NAME}`}
      />
      <Hero
        image={Banner}
        title="Living in the future."
        subtitle="Explore the future"
      />
      <About />
      <HowWeDo tabs={makes} />
      <Team mates={teams} directorship={boards} tradings={tradings} />
      <MosaicSection
        title="Seja um corretor"
        description="Faça seu cadastro e fique por dentro de todos os lançamentos da Aikon Empreendimentos."
        buttonLabel="Cadastre-se"
        url="/corretor/"
        image={BeABroker}
      />
      <Footer />
      <WhatsApp />
    </>
  )
}

export default AboutPage
